@import "./index";

.App {
  text-align: center;
  overflow: hidden;
}

.popover-overlay-helper {
  z-index: 1;
  position: fixed;
  //background: red;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: none;

  &.active {
    display: block;
  }
}


.topmenu {
  /*margin: 19px 0 0 34px;*/
  margin: 28px 0 0 56px;
}
a {
  text-decoration: none; padding: 0;
}

span.logo {
  font-size: 35px;
  margin-right: 10px;
}
.logo-image {
  width: 155px;

  @media screen and (max-width: 720px) {
    padding-top: 1rem;
    padding-bottom: .5rem;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.swap-window, .pool-data {
  width: 500px;
  box-sizing: border-box;
  position: relative;
  max-width: 90%;
  border-radius: 10px;
  font-family: 'Roboto', sans-serif;
  background: white;
}

.swap-window {
  margin: 150px auto 16px;
  padding: 32px 32px;
}

.swap-window div {
  margin: 5px 0;
}

.choose-option {
  cursor: pointer;
}

.stakeModule input, .swap-window input {
  padding: 16px;
  border-radius: 12px;
  box-shadow: none;
  background: $color-primary-blue-100;
  border: none;
  margin: 10px 0 10px;
  text-align: left;
  color: $color-primary-blue-800;
  font-size: 20px;
  max-width: 200px;

  @media screen and (max-width: 720px) {
    max-width: 100%;
  }
}

.stakeModule input::placeholder, .swap-window input::placeholder {
  color: $color-primary-blue-650;
}

.stakeModule .afterInput, .swap-window .afterInput {
  color: $color-primary-blue-650;
  position: absolute;
  font-size: 12px;
  right: 18px;
  top: 18px;
  width: 320px;
  text-align: right;
  transition: color .5s;

  @media screen and (max-width: 720px) {
    top: 100px;
  }
}

.swap-window .afterInput:hover {
  cursor: pointer;
  color: $color-primary-blue-800;
}

.swap-window .comp {
  width: 100%;
  display: flex;
  justify-content: space-between;
  vertical-align: center;
  margin: 1px auto;
  flex-grow: 1;
  position: relative;

  @media screen and (max-width: 720px) {
    flex-direction: column;
  }
}

.choose-option.chosen {
  color: green;
}

//BUTTONS

.button {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  flex-grow: 0;
  margin: 16px 24px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &__icon {

    justify-content: space-between !important;
    &-image {
      width: 24px;
      height: 24px;
    }
  }
}

.primary {
  background: $color-primary-blue-500;
  color: $color-white;
  border: none;
  &:hover {
    background: $color-primary-blue-550;
  }
  &:disabled {
    background: $color-primary-blue-300;
  }
}

.secondary {
  border: 1px solid $color-primary-blue-100 !important;
  box-sizing: border-box;
  color: $color-primary-blue-800;
  background-color: $color-white;
  padding: 8px 20px;
  &:hover {
    background: $color-primary-blue-100;
    color: $color-primary-blue-800;
  }
  &:disabled {
    background: $color-primary-blue-300;
    opacity: 0.4;
    border: 1px solid $color-primary-blue-100;
  }
}

.large {
  //width: 96px;
  height: 56px;
  border-radius: 12px;
  padding: 16px 24px;
}

.medium {
  //width: 88px;
  height: 40px;
  border-radius: 10px;
  padding: 8px 20px;

  @media screen and (max-width: 500px) {
    width: max-content;
  }
}

.wide {
  width: 100%;
  margin: 12px 0;
}

.modal {
  &-content {
    background: $color-white;
    border: 1px solid $color-primary-blue-100;
    box-shadow: 0 8px 56px rgba(54, 56, 112, 0.16);
    border-radius: 16px !important;
  }
  &-header {
    border: 1px solid $color-primary-blue-100;
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;

    button {
      border: none;
      background: $color-white;

      span {
        color: $color-primary-blue-300;
        font-size: 32px;
        line-height: 40px;
      }
    }
}
}

//END OF BUTTONS

//TEXT FIELDS

.textField {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 16px 16px 46px;

  position: relative;
  width: 100%;
  height: 56px;
  font-size: 20px;
  line-height: 24px;
  background: $color-primary-blue-100;
  border-radius: 12px;
  border: transparent;
  color: $color-primary-blue-800;


  @media screen and (max-width: 720px) {
    height: 48px;
  }

  &::placeholder {
    color: $color-primary-blue-650;
  }

  &:active {
    color: $color-primary-blue-800;
    background: $color-white;
    border: 1px solid $color-primary-blue-500 !important;
  }

  &:hover, &:focus-visible {
    background: $color-primary-blue-100;
    border: 1px solid $color-primary-blue-300 !important;
  }

  &__icon-left {
    width: 24px;
    height: 24px;
    position: absolute;
    pointer-events: none;
    z-index: 3;
    margin: 1rem;
  }
}

//END OF TEXT FIELDS


.pool-data {
  margin: 0 auto 100px;
  padding: 18px 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.poolliq-image {
  display: block;
  margin: -18px -32px -18px -0px;
  border-radius: 12px;
  height: 100%;
}

.poolliq-text {
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  color: $color-primary-blue-650;
}

.poolliq-value {
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: $color-primary-blue-800;
}

.change-button{
  cursor: pointer;
  img {
    will-change: transform;
    transform: rotate(0deg);
    transform-origin: 50% 50%;
    margin: 10px;
    transition: transform 0.5s ease-in-out;
    @media screen and (max-width: 720px) {
      width: 40px;
      height: 40px;
    }
  }
  &--rotate{
    img{
      transform: rotate(180deg);
    }
  }
}


.tokenLogo, .smallLogo {
  border: 1px solid $color-primary-blue-100;
  border-radius: 12px;
  position: relative;
  //margin: auto;
  width: 56px;
  height: 56px;
}

.smallLogo {
  width: 24px;
  height: 24px;
}

.tokenData {
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  padding: 0;

}

.popover {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  box-shadow: 0px 8px 56px rgba(54, 56, 112, 0.16);
  min-width: 360px;
  max-width: unset;

  border: 1px solid $color-primary-blue-100;
  border-radius: 1rem !important;
  position: absolute;

  left: calc(50% - 360px/2);
  top: calc(50% - 496px/2);

  >.popover {
    //min-width: 240px;
    max-width: 400px;
    border: transparent;
  }

  &-inner {
    width: 100%;
  }

  &__header {
    color: $color-primary-blue-800;
    display: flex;
    justify-content: space-between;
    align-items: center;
    //width: 368px;
    height: 56px;
    background: $color-white !important;
    //padding: 16px;

    border-bottom: 1px solid #F1F2FE;
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;

    &-desc {
      color: $color-primary-blue-800;
      font-weight: 500;
      flex-grow: 10;
      @media screen and (max-width: 720px) {
        flex-wrap: wrap;
        padding-left: 48px;
      }
    }

    &-icon {
      flex-grow: 0;
      cursor: pointer;
      @media screen and (max-width: 720px) {
        align-self: flex-start;
        padding-top: 21px;
      }
    }
  }

  &--body {
    align-self: flex-start;

    &-small {
      position: relative;
      align-self: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      border: unset;
      //box-shadow: 0px 8px 24px rgba(54, 56, 112, 0.16);
      //border-radius: 12px;
      cursor: pointer;
      min-width: 220px !important;
      height: 130px;
      padding-top: 10px;
      padding-bottom: 10px;

      .popover-body {
        padding: 0 !important;
      }
    }

    &-item {
      font-size: 14px;
      line-height: 20px;
      color: $color-primary-blue-800;
      //padding-top: 10px;
      //padding-bottom: 10px;
      padding: 10px 12px;

      a {
        text-decoration: none;
        color: $color-primary-blue-800;
      }
    }

    &-logout {
      border-top: 1px solid $color-primary-blue-100;
    }

    &-input {
      margin-bottom: 1rem;
      border-bottom: none;
    }
  }

  &-body {
    padding: 24px !important;
  }
}

//.popover {
//  min-width: 360px;
//  max-width: unset;
//
//  border: 1px solid $color-primary-blue-100;
//  border-radius: 1rem !important;
//  box-shadow: 0px 8px 56px rgba(54, 56, 112, 0.16);
//  position: absolute;
//
//  left: calc(50% - 360px/2);
//  top: calc(50% - 496px/2);
//
//  &-header {
//    padding: 21px 29px 21px 24px !important;
//  }
//
//  &-body {
//    padding: 24px !important;
//  }
//}

.tokenName {
  display: flex;
  flex-direction: column;
  //width: 101px;
  justify-content: center;

  &-name {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $color-primary-blue-800;
    text-align: left;
    margin-left: 10px;
  }

  &-text {
    font-size: 14px;
    line-height: 20px;
    color: $color-primary-blue-650;
    text-align: left;
    margin-left: 10px;
  }
}

.popover, .custom-popover, .infoPopup, .tokenChoice, .tokenChoice-image, .tokenChoice-name {
  z-index: 1100 !important;
}

.tokenPair {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  @media screen and (max-width: 720px) {
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
}

.arrow {
  padding-bottom: 16px;
  padding-left: 12px;

  @media screen and (max-width: 720px) {
    width: 24px;
    height: 24px;
    background: $color-primary-blue-100;
    border-radius: 6px;
    flex: none;
    order: 2;
    flex-grow: 0;
    padding: 6px;
    margin: 0px 8px;
  }

  &-portfolio {
    margin: 4px;
  }
}

.comp.rate {
  justify-content: flex-start;
  font-size: 14px;
  line-height: 20px;
  color: $color-primary-blue-800;

  @media screen and (max-width: 720px) {
    flex-direction: row !important;
  }

  .activated {
    transform: rotate(180deg);
    transition: all 0.7s ease-out;
    will-change: transform;
  }
}

.rateText {
  font-size: 14px;
  line-height: 20px;
  color: $color-primary-blue-800;
  display: block;
  margin: auto 4px;
}

.infoIcon {
  border: none;
  background: none;
  font-size: 14px;
  line-height: 20px;
  color: $color-primary-blue-650;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.infoPopup .popover {
  padding: 20px 25px 10px !important;
  z-index: 1000;
}

.custom-popover .popover {
  padding: 12px 4px 0px;
  background: $color-white;
  border: 1px solid $color-primary-blue-100;
  box-shadow: 0px 8px 24px rgba(54, 56, 112, 0.16);
  border-radius: 12px;
}



.tokenChoice {
  padding: 8px 12px 8px 0;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.tokenChoice-image {
  width: 40px;
  height: 40px;
  border-radius: 12px;
}

.tokenChoice-name {
  font-size: 14px;
  line-height: 20px;
  vertical-align: center;
  margin: 2px 8px;
  color: $color-primary-blue-800;
}

.non-visible {
  display: none;
}

.portfolio-value, .balance, .address {
  font-size: 16px;
  color: $color-primary-blue-800;
  margin: auto 12px;
}

.address {
  border: 1px solid $color-primary-blue-100;
  border-radius: 10px;
}

.portfolio-value {
  display: inline-block;
}

.tokenBalance {
  margin: 10px auto;
  height: 24px;
  width: 200px;
  vertical-align: center;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.smallLogo {
  margin-right: 8px;
}

.tokenBalance-balance {
  color: #4f647b;
  line-height: 18px;
  vertical-align: center;
  font-size: 14px;
}

.wallet-popup {
  box-sizing: border-box;
  width: 400px;
}

.wallet-widget {
  background: none;
  border: none;
}
.wallet-module {
  margin-top: 0;
  align-self: center;
  display: flex;
  flex-direction: row;

  button {
    margin: 0;

    @media screen and (max-width: 1000px) {
      margin-left: 16px;
    }
  }
}

.waves-logo {
  width: 16px;
  height: 16px;
}

//FOOTER

.footer {
  min-height: 320px;
  background: $color-primary-blue-800;
  flex: none;
  order: 5;
  flex-grow: 0;
  margin: 0px 0px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  padding: 3.5rem 4rem 72px 4rem;

  @media screen and (max-width: 720px) {
    padding: 2rem 1.5rem 1rem 1.5rem;
    flex-direction: column-reverse;
    justify-content: flex-start;
  }

  &__lc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 2;

    &-logo {
      height: auto;
      margin-bottom: 10px;
      align-self: flex-start;

      @media screen and (max-width: 720px) {
        display: none;
      }
    }
    &-copyright {
      font-size: 14px;
      line-height: 20px;
      color: $color-primary-blue-650;
      text-align: left;
      padding-left: 1rem;
    }
  }

  &__rc {
    display: inline-flex;
    columns: 3;
    flex-grow: 6;
    justify-content: space-around;
    //padding: 4.1rem 4rem 2rem 4rem;
    flex-wrap: nowrap;

    a {
      color: $color-white;
      text-decoration: none;
      font-size: 16px;
      line-height: 24px;
      transition: color .5s;
      cursor: pointer;
    }

    .landing__subtitle {
      color: $color-primary-blue-650;
      text-transform: none;
      margin-top: 0;
      @media screen and (max-width: 375px) {
        margin-bottom: 1rem;
      }
    }

    @media screen and (max-width: 375px) {
      flex-grow: 2;
      display: flex;
      columns: unset;
      padding: unset;
    }

    @media screen and (max-width: 720px) {
      justify-content: space-between;
    }

    &--column {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: max-content;
      @media screen and (max-width: 720px) {
        text-align: left;
      }
    }
  }
}

//END OF FOOTER

.banner a {
  color: $color-primary-blue-650;
  text-decoration: none;
  margin: 0 10px;
  transition: color .5s;
}

.banner a:hover {
  color: $color-primary-blue-800;
}

.banner {
  padding: 15px;
  margin-bottom: 40px !important;
  border-radius: 12px;
  color: $color-primary-blue-800;
  background: $color-primary-blue-100;

  &__top {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 56px;
    background-color: $color-primary-blue-800;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    display: flex;
    justify-content: space-between;
    align-items: center;
    scroll-behavior: opacity($color-white);

    @media screen and (max-width: 720px) {
      flex-direction: row;
      height: 80px;
    }

    &--closed {
      display: none;
    }

    &-desc {
      color: $color-primary-blue-300;
      font-weight: 400;
      flex-grow: 10;
      @media screen and (max-width: 720px) {
        flex-wrap: wrap;
        padding-left: 48px;
      }
    }
    &-icon {
      flex-grow: 0;
      padding-right: 45px;
      cursor: pointer;
      @media screen and (max-width: 720px) {
        padding-right: 21px;
        align-self: flex-start;
        padding-top: 21px;
      }
    }
  }
}

.banner span {
  line-height: 10px;

}

.banner a {
  margin: 0;
}


@media screen and (max-width: 720px) {
  .comp input {
    width: 100%;
  }
  .header-menu {
    display: flex;
    flex-direction: column;
  }

  header a .menu-element:hover, header a.chosen .menu-element {
    border-bottom-width: 1px;
  }
  .header-menu .topmenu {
    margin: 5px 0;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  header a.chosen .menu-element, header .menu-element:hover {
    padding-bottom: 12px;
  }
  header a.chosen .menu-element, header .menu-element:hover {
    padding-bottom: 8px;
  }

  .swap-window {
    margin-top: 200px;
  }
  .poolliq-image {
    display: none;
  }
}

.header {
  display: flex;
  background: $color-white;
  box-shadow: 0px 8px 56px rgba(54, 56, 112, 0.16);
  width: 100%;
  position: fixed;
  min-height: 80px;
  align-items: center;
  z-index: 100;
  color: white;
  top: 0;
  left: 0;
  right: 0;

  &__with-banner {
    top: 56px;
    display: flex;
    background: $color-white;
    box-shadow: 0px 8px 56px rgba(54, 56, 112, 0.16);
    width: 100%;
    position: fixed;
    min-height: 80px;
    align-items: center;
    z-index: 100;
    color: white;
    left: 0;
    right: 0;

    @media screen and (max-width: 720px) {
      top: 80px;
    }
  }

  &__container {
    display: flex;
    //max-width: 100vw;
    justify-content: space-between;
    width: 100%;
    padding: 0 2rem;
    min-height: 80px;

    @media screen and (max-width: 1000px) {
      display: flex;
      flex-direction: column;
      padding: 0;
      //width: 90%;
      margin: 0 auto;
      //grid-template-columns: calc((var(--layout-width) * .2) + (100% - var(--layout-width)) * .5 - .5rem) 1fr;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 6;
    transition: .25s ease-in-out;
    overflow: hidden;

    @media screen and (max-width: 1000px) {
      position: absolute;
      //top: var(--header-height);
      left: 0;
      right: 0;
      max-height: 0;
      //padding: 0 var(--layout-gap);

      margin: 5px 0;
      display: flex;
      flex-direction: column;
      text-align: left;
    }

    &--open {
      //max-height: 400px;
      border-radius: 0 0 24px 24px;
      display: flex;
      flex-direction: row;

      @media screen and (max-width: 1000px) {
        flex-direction: column;
      }
    }

    &-link {
      font-size: 14px;
      line-height: 24px;
      color: $color-primary-blue-650;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 24px 12px;

      &:hover {
        color: $color-primary-blue-800;
        border-bottom: 4px solid $color-primary-blue-500;
        padding-bottom: 28px;
        margin-bottom: 0;

        @media screen and (max-width: 1000px) {
          border-bottom: none;
          padding-bottom: 0;
        }

        @media screen and (max-width: 720px) {
          border-bottom: none;
          padding-bottom: 0;
        }
      }

      a.chosen .header__menu-link:hover {
        color: $color-primary-blue-800;
        border-bottom: 4px solid $color-primary-blue-500;
        padding-bottom: 28px;
        margin-bottom: 0;

        @media screen and (max-width: 1000px) {
          border-bottom: none;
          padding-bottom: 0;
        }

        @media screen and (max-width: 720px) {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media screen and (max-width: 1000px) {
      //margin-left: -1.5rem;
      align-items: flex-start;
      flex-direction: column;
      padding-bottom: 1rem;
    }

    @media screen and (max-width: 720px) {
      margin-left: 10px;
    }
  }

  &__link {

    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $color-primary-blue-650;
    margin: 0 14px;
    cursor: pointer;
    transition: color .5s;
    box-sizing: border-box;

    > a {
      text-decoration: none;
      padding: 0;
    }

    a.chosen .header__menu-link:hover {
      color: $color-primary-blue-800;
      border-bottom: 4px solid $color-primary-blue-500;
      padding-bottom: 28px;
      margin-bottom: 0;

      @media screen and (max-width: 720px) {
        border-bottom: none;
        padding-bottom: 0;
      }
    }

    //&:hover {
    //  border-bottom: 4px solid $color-primary-blue-300;
    //  padding-bottom: 26px;
    //  color: $color-primary-blue-650;
    //  /*margin: 12px 14px 0;*/
    //}
    @media screen and (max-width: 720px) {
      margin: 5px 0;
      display: flex;
      flex-direction: column;
      text-align: left;
    }
  }

  &__toggle {
    display: none;
    transform: scale(0.4);
    width: 100px;
    height: 100px;
    position: relative;
    cursor: pointer;
    border-radius: 5px;

    * {
      transition: .25s ease-in-out;
    }

    @media screen and (max-width: 1000px) {
      display: block;
    }
  }

  /* Burger */
  &__burger {
    position: absolute;
    height: 24px;
    width: 24px;

    &-item {
      display: block;
      width: 60px;
      height: 5px;
      position: relative;
      top: 24px;
      left: 20px;
      margin: 10px 0;
      background: $color-primary-blue-650;
      border-radius: 2px;

      &:nth-child(1) {
        transition-delay: .5s;
      }

      &:nth-child(2) {
        transition-delay: .625s;
      }

      &:nth-child(3) {
        transition-delay: .75s;
      }
    }
  }

  &__toggle--open &__burger {
    background: transparent;
    padding-right: 20px;

    &:hover, &:focus {
      background: transparent;
    }

    .header__burger-item {
      width: 0;

      &:nth-child(1) {
        transition-delay: 0s;
      }

      &:nth-child(2) {
        transition-delay: .125s;
      }

      &:nth-child(3) {
        transition-delay: .25s;
      }
    }
  }

  &__toggle--open {
    padding-right: 50px;
  }

  /* Cross */
  &__cross {
    position: absolute;
    height: 40px;
    width: 40px;
    transform: rotate(45deg);
    transform-origin: 50% 50%;

    &-item {
      display: block;
      background: $color-primary-blue-650;
      border-radius: 2px;

      &:nth-child(1) {
        height: 0;
        width: 2px;
        position: absolute;
        top: 20%;
        left: 48%;
        transition-delay: 0s;
      }

      &:nth-child(2) {
        width: 0;
        height: 2px;
        position: absolute;
        left: 20%;
        top: 48%;
        transition-delay: .25s;
      }
    }
  }

  &__toggle--open &__cross {
    background: transparent;

    .header__cross-item {
      &:nth-child(1) {
        height: 60%;
        transition-delay: .625s;
      }

      &:nth-child(2) {
        width: 60%;
        transition-delay: .375s;
      }
    }
  }

  .header__toggle--open, .header__burger {
    @media screen and (min-width: 721px) {
      display: none;
    }
  }

  /* Logo */
  &__logo {
    align-items: center;
    display: flex;
    outline: none;
    flex-grow: 0;
    padding-left: 40px;

    @media screen and (max-width: 1000px) {
      justify-content: space-between;
      //width: 95%;
      padding-left: unset;
    }
    @media screen and (max-width: 500px) {
      //width: 80%;
    }
  }

  &__login {
    display: flex;
    padding-right: 40px;
    @media screen and (min-width: 1001px) {
      padding-right: 0;
    }
    @media screen and (max-width: 1000px) {
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 2rem;
    }

    //@media screen and (max-width: 500px) {
    //  margin-bottom: 2rem;
    //}
  }
}