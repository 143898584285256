$color-white: #ffffff;
$color-black: #000000;

$color-primary-blue-100: #F1F2FE;
$color-primary-blue-300: #C6C9F4;
$color-primary-blue-500: #7075E9;
$color-primary-blue-550: #6563DD;
$color-primary-blue-650: #8082C5;
$color-primary-blue-800: #363870;

$color-success-500: #35A15A;
$color-success-100: #E6F3EB;
$color-attention-500: #EDAA8A;
$color-attention-100: #FCF4F1;

$color-error-500: #ED827E;
$color-error-100: #FCF0EF;


