@import "./index";

h1 {
    color: $color-primary-blue-800;
    font-size: 48px;
    line-height: 56px;

    @media screen and (max-width: 375px) {
        font-size: 32px;
        line-height: 40px;
    }
}

h2 {
    font-size: 32px;
    line-height: 40px;
    color: $color-primary-blue-650;
    @media screen and (max-width: 375px) {
        font-size: 16px;
        line-height: 24px;
    }
    @media screen and (max-width: 720px) {
        font-size: 24px;
        line-height: 1.2;
    }
}

h1, h2 {
    font-family: "Roboto", sans-serif;
    max-width: 100%;
    margin: auto;
    font-style: normal;
    font-weight: 500;
}

a {
    text-decoration: none !important;
}

.landing {
    width: 100%;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    &__title {

    }

    &__subtitle {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: $color-primary-blue-500;
        text-transform: uppercase;
        margin-top: 96px;
        margin-bottom: 0.5rem;
        @media screen and (max-width: 400px) {
            margin-top: 4rem;
        }
    }

    &__desc {
        color: $color-primary-blue-650;
        font-size: 16px;
        line-height: 24px;
    }

    &__intro {
        padding-left: 140px;
        padding-right: 140px;
        display: flex;
        align-items: center;
        width: 100%;
        background: white;
        position: relative;

        @media screen and (max-width: 1000px) {
            padding: 0 40px;
        }

        @media screen and (max-width: 720px) {
            flex-direction: column;
            align-items: flex-start;
            padding-top: 80px;
            padding-left: 2rem;
            padding-right: 2rem;
        }

        @media screen and (max-width: 400px) {
            padding-left: 1rem;
            padding-right: 1rem;
        }

        button {
            padding: 16px 50px;
            margin-left: 0;
        }

        &--lc {
            text-align: left;

            @media screen and (max-width: 1000px) {
                margin-bottom: 46px;
            }
        }

        &--rc {

            &-image {
                display: block;
                margin: 80px auto 0;
                max-width: 80%;

                @media screen and (max-width: 1000px) {
                    max-width: 80%;
                    align-self: center;
                    margin: 40px auto 0;
                }

                @media screen and (max-width: 720px) {
                    margin: 0 auto 46px;
                }

                @media screen and (max-width: 500px) {
                    margin: 0 0 46px 0;
                }
            }
        }

        &-more {
            position: absolute;
            bottom: 0;
            left: 120px;
            background: $color-primary-blue-100;
            border-radius: 12px 12px 0px 0px;
            padding: 20px 24px 16px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $color-primary-blue-500;
            cursor: pointer;
            display: flex;
            flex-wrap: nowrap;
            align-items: baseline;

            @media screen and (min-width: 1400px) {
                left: 500px;
            }

            @media screen and (max-width: 375px) {
                padding: 16px 18px 9px;
                line-height: 1;
                left: 20px;
            }

            img {
                margin-left: 10px;
            }
        }

    }

    &__about {
        background: $color-primary-blue-100;
        display: flex;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media screen and (max-width: 720px) {
            padding-left: 1rem;
            padding-right: 1rem;
        }

        &--advantages {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 100px;
            margin-top: 60px;

            @media screen and (max-width: 720px) {
                margin-top: 30px;
                margin-bottom: 60px;
            }

            &-item {
                width: 240px;
                margin: 20px;

                @media screen and (max-width: 720px) {
                    width: 90%;
                }

                @media screen and (max-width: 400px) {
                    margin-top: 0;
                }

                h2 {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 32px;
                    text-align: center;
                    color: $color-primary-blue-800;
                    margin-bottom: 8px;
                }

                p {
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                    color: $color-primary-blue-650;
                }

                img {
                    width: 80px;
                }
            }
        }

        &--banner {
            position: relative;
            background-image: url(./img/landing-banner-with-image-desktop.svg);
            background-repeat: no-repeat;
            height: auto;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: -130px;
            max-width: 1160px;
            min-width: 80%;
            background-size: contain;

            @media screen and (max-width: 1000px) {
                background-image: url(./img/landing-banner-with-image.svg);
                position: relative;
                padding-bottom: 2rem;
                padding-top: 2rem;
                padding-left: 2rem;
                //height: unset;
            }

            @media screen and (max-width: 720px) {

                min-height: 154px;
                padding-bottom: 1.5rem;
                padding-top: 1.5rem;
                padding-left: 1.5rem;
            }

            @media screen and (max-width: 500px) {
                width: 100%;
                min-height: 154px;
                padding: 0;
                margin-bottom: -60px;
            }

            @media screen and (max-width: 375px) {
                min-height: 104px;
                button {
                    margin-top: 0;
                }
            }

            &-wrapper {
                position: relative;
                margin-top: 100px;
            }

            &--lc {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                color: $color-white;
                text-align: left;
                margin: 48px;
                font-style: normal;
                font-weight: 500;
                font-size: 32px;
                line-height: 40px;
                @media screen and (max-width: 720px) {
                    margin: 1.5rem;
                    font-size: 16px;
                    line-height: 24px;
                    flex-grow: 1;
                }

                &-desc {
                }
            }

            button {
                margin-left: 0;
                margin-bottom: 0;

                @media screen and (max-width: 720px) {
                    height: auto;
                    width: max-content;
                    margin-right: 0;
                }
            }
        }
    }

    &__traders {
        background: white;
        padding-top: 200px;

        @media screen and (max-width: 720px) {
            padding-left: 2rem;
            padding-right: 2rem;
        }

        @media screen and (max-width: 500px) {
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: 4rem;
        }

        a {
            text-decoration: none; padding: 0;
        }
    }

    &__prices {
        margin: 56px auto;
        max-width: 790px;
        border: 1px solid $color-primary-blue-100;
        border-radius: 12px;
        padding: 14px 24px;

        @media screen and (max-width: 720px) {
            padding-left: 1rem;
            padding-right: 1rem;
        }

        p {
            margin-top: 0;
            margin-bottom: 0;
        }

        &--logo {
            width: 24px;
            height: 24px;
            margin: 12px 6px 12px 0;
            border-radius: 12px;
        }

        .table {
            &-head {
                color: $color-primary-blue-650;
                align-items: center;
            }
            &-row {
                display: flex;
                color: $color-primary-blue-800;
                padding: 12px 19px;
                border-bottom: 1px solid $color-primary-blue-100;

                @media screen and (max-width: 720px) {
                    padding: 0;
                }
            }

            &-cell {
                width: 33%;
                vertical-align: center;
                //height: 24px;
                box-sizing: border-box;
                display: flex;
                height: 48px;
                line-height: 48px;
                align-items: center;
                &:first-child {
                    width: 50%;
                }
                &:last-child {
                    align-self: flex-end;
                }

                img {
                    width: 20px;
                    height: 20px;
                }

                .button.secondary.medium {
                    background-color: unset;
                    border: 1px solid $color-primary-blue-100;
                    box-sizing: border-box;
                    margin: 0;

                    &:hover {
                        background: $color-primary-blue-100;
                        border: 1px solid $color-primary-blue-100;
                    }
                }
            }
        }


        .table-body.table-row:last-child {
            align-self: flex-end;
        }

    }

    &__invest {
        padding: 0 70px;
        display: flex;
        align-items: center;
        width: 100%;
        background: $color-primary-blue-100;
        position: relative;
        @media screen and (max-width: 1000px) {
            padding: 0 60px;
        }

        @media screen and (min-width: 1001px) {
            padding: 0 140px;
        }

        @media screen and (max-width: 720px) {
            flex-direction: column;
            text-align: center;
            padding: 0 2rem;
        }

        @media screen and (max-width: 400px) {
            padding: 0 1rem;
            align-items: center;
        }



        button {
            padding: 16px 50px;
            margin-left: 0;
            text-decoration: none;
        }

        &--lc {
            text-align: left;
            flex-grow: 3;

            .landing__subtitle {
                text-align: left;
                @media screen and (max-width: 720px) {
                    text-align: center;
                }
            }

            .landing__title {
                max-width: 560px;
                @media screen and (max-width: 720px) {
                    text-align: center;
                }
            }

            .landing__desc {
                max-width: 400px;
                margin-bottom: 40px;
                a {
                    text-decoration: none;
                }
                @media screen and (max-width: 720px) {
                    text-align: center;
                }
            }
        }

        &--rc {
            flex-grow: 1;

            &-image {
                display: block;
                margin: auto;
                max-width: 100%;

                @media screen and (max-width: 1000px) {
                    max-width: 80%;
                }

                @media screen and (max-width: 720px) {
                    max-width: 343px;
                }
            }
        }

        &-more {
            position: absolute;
            bottom: 0;
            left: 120px;
            background: $color-primary-blue-100;
            border-radius: 12px 12px 0px 0px;
            padding: 20px 24px 16px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $color-primary-blue-500;
            cursor: pointer;

            img {
                margin-left: 10px;
            }
        }

    }
}

.ignore-link {
    display: inherit;
    text-decoration: none;
    color: inherit;
    padding: 20px;
}
